<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <h1>Acuerdo de privacidad</h1>
        <!-- <img :src="importedImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <div class="textCard">
          <!-- <h1>Cam kết bảo mật</h1> -->
          <!-- <div class="title-line"></div> -->
          <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import importedImg1 from "@/page/credusas/components/img/4_1.png";

export default {
  data() {
    return {
      altText: "protocolTitle",
      // importedImg1: importedImg1,
      text: `"Esta Política de Privacidad ("Política") se aplica a esta aplicación ("Aplicación"). Informa a los usuarios de la Aplicación y los servicios que proporciona ("usted") qué información recopila la Aplicación y cómo la Aplicación utiliza y comparte esta información.


La Aplicación recopila información de los usuarios de la Aplicación que puede identificar a un individuo específico ("Información de identificación personal"), así como información anónima que no puede asociarse con un individuo específico ("Información de identificación no personal").

La Aplicación recopila información de identificación personal cuando usted proporciona información de identificación personal a través del uso de la Aplicación, como al completar una o más etapas de una solicitud en línea (la "Solicitud") con un prestamista que participa en la red de prestamistas de la Aplicación (la " Aplicación") información. "Solicitar") para proporcionar ofertas de préstamos. "Socios prestamistas") o enviar preguntas a los solicitantes por correo electrónico. Los tipos de información de identificación personal recopilada por esta aplicación incluyen: nombre, dirección física, número de teléfono, número de seguro social, dirección de correo electrónico, fecha de nacimiento, número de licencia de conducir y estado, información de propiedad de la vivienda, estado de ciudadanía, información de empleo, servicio militar activo, información militar, monto y frecuencia de pago e información de cuenta bancaria.

Los tipos de información de identificación no personal recopilada por la Aplicación (ya sea directamente mediante el uso de "cookies" o mediante el uso de sistemas de seguimiento de terceros) incluyen: La dirección IP del dispositivo que realiza la solicitud; Su tipo de dispositivo e idioma ; Tiempos de acceso; Información sobre el acceso del usuario a la Aplicación Información Información sobre áreas de la aplicación; información sobre las páginas que los usuarios pueden elegir visitar dentro de la aplicación. Las direcciones IP normalmente no están asociadas con su dispositivo personal, sino con la puerta de enlace a través de la cual accede a Internet. Las direcciones IP no están asociadas con información de identificación personal. Una "cookie" es un fragmento de información que una aplicación envía a su dispositivo cuando la visualiza para recordar quién es usted sin identificarlo personalmente.

La Aplicación puede compartir esta información de identificación no personal con sus proveedores y otros terceros para estadísticas de la aplicación y análisis de tendencias. Esta información no está vinculada a ninguna información de identificación personal.

La Aplicación puede permitir que terceros, incluidos socios publicitarios y prestamistas personales, muestren anuncios en la Aplicación. Estas empresas pueden utilizar tecnologías de seguimiento, incluidas cookies, para recopilar información sobre los usuarios de la aplicación que ven o interactúan con estos anuncios. La Aplicación no proporciona información de identificación personal a estos terceros.

La Aplicación utiliza la información de identificación personal que usted proporciona a través de la Aplicación para entregar los productos o servicios que ha solicitado; para brindarle servicio al cliente; por correo electrónico, correo postal, teléfono y/o dispositivo móvil (incluso a través de servicios de mensajes de texto o "SMS"). "). "") comunicarse con usted sobre productos o servicios de la Aplicación, socios prestamistas u otros terceros que puedan ser de su interés; mostrar anuncios adaptados a sus intereses o antecedentes; utilizar la Aplicación para análisis de datos y estadísticos; hacer cumplir los términos de la Aplicación y condiciones y realizar cualquier otra función que se le describa cuando la Aplicación recopile información.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
img {
  display: block;
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1,
.img2 {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2 {
  width: 100%;
  margin: 0;
}
.img2{
  padding: 0 220px;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3f4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}
.textBox {
  margin: 70px 0 150px;
  width: 90%;
  font-size: 18px;
  color: #333333;
}
.textCard {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: 0 2px 20.8px -5px rgba(0, 0, 0, 0.25);
  margin: 10px 0 200px;
}

.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0baf60;
  margin: 0 500px;
}
.img1 {
  /* margin-top: 50px; */
  /* padding: 0 200px; */
  background: #FFD813;
  height: 150px;
  /* height: 240px; */
  display: flex;
  justify-content: center;
}
.img2{
  background: linear-gradient(to bottom,#FFD813 7%,#FFFFFF 7%);
}
h1{
  margin-left: 0;
  margin-top: 60px;
  font-weight: 900;
}
</style>